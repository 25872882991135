export const Footer = (props) => {
    return (
        <div id='footer'>
            <div className='col-md-12'>
                <div className='row'>
                    <div className='social'>
                    <ul>
                        <li>
                            <a href='https://twitter.com/ImagFriendsCo'>
                                <i className='fab fa-twitter'></i>
                            </a>
                        </li>
                        <li>
                            <a href='https://discord.gg/QJRQYpP6jc'>
                                <i className='fab fa-discord'></i>
                            </a>
                        </li>
                        <li>
                            <a href='mailto: imaginaryfriendsco@outlook.com'>
                                <i className='fa fa-paper-plane'></i>
                            </a>
                        </li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className='container text-center'>
                <p>
                    &copy; IMAGINARY FRIENDS CO. ALL RIGHTS RESERVED.
                </p>
            </div>
        </div>
    )
}
