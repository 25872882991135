export const Navigation = () => {
    return (
        <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
            <div className='container'>
                <div className='navbar-header'>
                    <button
                        type='button'
                        className='navbar-toggle collapsed'
                        data-toggle='collapse'
                        data-target='#myNavbar'
                    >
                        {' '}
                        <span className='sr-only'>Toggle navigation</span>{' '}
                        <span className='icon-bar'/>{' '}
                        <span className='icon-bar'/>{' '}
                        <span className='icon-bar'/>{' '}
                    </button>
                    <a className='navbar-brand page-scroll' href='#home'>
                        <img src="img/imag-friends-co.png" alt='...' className='ifc-logo-img' />
                    </a>{' '}
                </div>
                <div class="collapse navbar-collapse" id="myNavbar">
                    <ul className='nav navbar-nav navbar-right'>
                        <li>
                            <a href='https://twitter.com/ImagFriendsCo'>
                                <i className='fab fa-twitter'></i>
                            </a>
                        </li>
                        <li>
                            <a href='https://discord.gg/QJRQYpP6jc'>
                                <i className='fab fa-discord'></i>
                            </a>
                        </li>
                    </ul>
                    <ul className='nav navbar-nav navbar-right'>
                        <li>
                            <a href='#about' className='page-scroll'>
                                About
                            </a>
                        </li>
                        <li>
                            <a href='#roadmap' className='page-scroll'>
                                Roadmap
                            </a>
                        </li>
                        <li>
                            <a href='#specs' className='page-scroll'>
                                Specs
                            </a>
                        </li>
                        <li>
                            <a href='#team' className='page-scroll'>
                                Team
                            </a>
                        </li>
                    </ul>

                </div>
            </div>
        </nav>
    )
}
