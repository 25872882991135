import {Link } from "react-router-dom";

export const Header = (props) => {
    return (
        <div className="intro">
            <container>
                <img src="img/peeking-header-desktop-2000x665.jpg" className="intro-image-desktop" alt="" />{" "}
                <img src="img/peeking-header-mobile-1200x800.jpg" className="intro-image-mobile" alt="" />{" "}
                <div class="row">
                    <div class="col-md-offset-2 col-md-4">
                        <div className="intro-btn-div">
                            <a href="https://ifc.thetadrop.com" rel="noreferrer" target="_blank"><button className="btn"><b>Adopt a Friend</b></button></a>
                        </div>
                    </div>
                    <div class="col-md-4">
                    <div className="intro-btn-div">
                        <Link to="/friendzone"><button className="btn-friend-zone "><b>FriendZone</b></button>
                        </Link>
                        </div>
                    </div>
                </div>
            </container>
        </div>


    )
}
