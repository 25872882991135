import React from 'react';
import { About } from "./components/about"
import { Header } from "./components/header";
import { Navigation } from "./components/navigation";
import { Roadmap } from "./components/roadmap";
import { Specs } from "./components/specs";
import { Team } from "./components/team";
import { Footer } from "./components/footer";
import SmoothScroll from "smooth-scroll";
import './Home.css';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Home = () => {

  return (
      <div>
        <Navigation/>
        {<Header/>}
        {<About/>}
        {<Roadmap/>}
        {<Specs/>}
        {<Team/>}
        {<Footer/>}
      </div>
  );
};

export default Home;
