export const Specs = () => {
    return (
        <div id="specs" className='text-center'>
            <div className="container">
                <div className='section-title'>
                    <h2>Adoption Specs</h2>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h3>2,045 unique NFT friends living on the Theta blockchain</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h3>There are six types of Imaginary Friend species (some rarer than others)</h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12'>
                        <h3>Friends are programmatically generated with many different possible traits and accessories</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};
