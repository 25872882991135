export const Roadmap = () => {
    return (
        <div id="roadmap" className='text-center'>
            <div className="container">
                <div className='section-title'>
                    <h2>Roadmap</h2>
                </div>
                    <div className='row'>
                        <div className='col-sm-16'>
                            <h3>Phase 1</h3>
                            <ul>
                                <li>Winter launch</li>
                                <li>Giveaways / Airdrops</li>
                                <li>IFC ThetaTV channel</li>
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-16'>
                            <h3>Phase 2</h3>
                            <ul>
                                <li>Merch store</li>
                                <li>Friend Quiz</li>
                                <li>Friends Only chat</li>
                                <li>Airdrops & Challenges</li>
                                <li>Create your own friend competition</li>
                            </ul>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-16'>
                            <h3>Phase 3</h3>
                            <ul>
                                <li>Surprise drop</li>
                                <li>Voting rights on future roadmap</li>
                                <li>Access to the exclusive <i>Friend Zone</i> <br/>(the only friend zone you actually want to be in)</li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>
    );
};
