export const About = () => {
    return (
        <div id="about">
            <div className="container">
                <div className="row">
                    <div className="col-xs-12 col-md-6">
                        {" "}
                        <img src="img/gary-polaroid.png" className="img-responsive" alt="" />{" "}
                    </div>
                    <div className="col-xs-12 col-md-6">
                        <div className="about-text">
                            <h2>About Us</h2>
                            <h3>We're Imaginary Friends Co. We formed to help the Imaginary Friends get out of their Imaginary Dimension and onto the Theta blockchain. Sadly, their world has been overrun by Imaginary Enemies :(  </h3>
                        </div>
                        <div className="about-text">
                            <h3>We hired Gary (see polaroid pic, fully vetted) to help them escape their dimension. Now that they've safely arrived on the blockchain, we just need your help giving them a forever home.</h3>
                        </div>
                        <div className="about-asterisk">
                            *Note: 56 Packs were withheld for giveaways, prizes, and the team.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
