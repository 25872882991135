export const Team = (props) => {
    return (
        <div id="team" className="text-center">
            <div className='container'>
                <div className='col-md-8 col-md-offset-2 section-title'>
                    <h2>Our Team</h2>
                    <p>
                        Two siblings, one cousin
                    </p>
                </div>
                <div id='row'>
                    <div className='col-md-4 team'>
                        <div className='thumbnail'>
                            {' '}
                            <img src="img/team/B-pic.png" alt="" className='team-img' />
                            <div className='caption'>
                                <h4>Jessie</h4>
                                <p>Our artist, Jessie, is a full-time Art Director and digital media artist in the product development industry. With over 10 years of experience in surface design and character illustration, her artwork can be found in many popular retail stores such as Target, Wayfair, and T.J. Maxx. When unplugged, she can be found painting rainbows and planting trees.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='row'>
                    <div className='col-md-4 team'>
                        <div className='thumbnail'>
                            {' '}
                            <img src="img/team/K-pic.png" alt="" className='team-img' />
                            <div className='caption'>
                                <h4>Kendall</h4>
                                <p>Our marketer/social media manager, Kendall, is a full-time Army National Guard recruiter. She has 6 years of military experience and initially enlisted as a medic. She is also the social media manager and content creator/designer for her recruiting and retention team. In Kendall's off-time, she's usually playing games on her PC, taking a nap, or hanging out with her pup, Aleu.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id='row'>
                    <div className='col-md-4 team'>
                        <div className='thumbnail'>
                            {' '}
                            <img src="img/team/J-pic.png" alt="" className='team-img' />
                            <div className='caption'>
                                <h4>Johanna</h4>
                                <p>Our developer, Jo, studied computer science in college and has 8 years of experience in the tech industry. When she's not coding, she's reading a book, dancing at an EDM concert, or playing with her overly energetic pug.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
